import Swiper from "swiper";
import { Thumbs } from "swiper/modules";
Swiper.use([Thumbs]);
import "swiper/css";
import "swiper/css/thumbs";

document.addEventListener('DOMContentLoaded', (event) => {
  const galleries = document.querySelectorAll(".gallery");
  
  galleries.forEach((gallery, index) => {
    const rowIndex = index + 1;  // Assuming row index starts from 1
    const thumbsSelector = `.gallery-thumbs_${rowIndex}`;
    const topSelector = `#gal-top_${rowIndex}`;
    
    const thumbsContainer = document.querySelector(thumbsSelector);
    const topContainer = document.querySelector(topSelector);

    let galleryThumbs = null;
    if (thumbsContainer) {
      galleryThumbs = new Swiper(thumbsContainer, {
        spaceBetween: 10,
        slidesPerView: 5,
        threshold: 5,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        centeredSlides: true,
        slideToClickedSlide: true,
      });
    } else {
      console.error(`Thumbs container not found for ${thumbsSelector}`);
    }

    let galleryTop = null;
    if (topContainer) {
      galleryTop = new Swiper(topContainer, {
        spaceBetween: 10,
        thumbs: {
          swiper: galleryThumbs,
        },
      });
    } else {
      console.error(`Top container not found for ${topSelector}`);
    }

    if (galleryTop && galleryThumbs) {
      galleryTop.on("slideChange", function () {
        galleryThumbs.slideTo(galleryTop.activeIndex);
      });
      galleryThumbs.on("slideChange", function () {
        galleryTop.slideTo(galleryThumbs.activeIndex);
      });
    } else {
      console.error('Swiper instances not initialized correctly');
    }
  });
});