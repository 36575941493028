const mainheader = document.querySelector(".main-header");
const toggle = document.querySelector("#mobile-menu-click");
const body = document.querySelector("body");
const linkWrapper = document.querySelector(".lusso_link-wrapper");

const menu = document.querySelector(".lusso_menu");
toggle.addEventListener("click", function () {
  if (menu.getAttribute("data-nav-menu-open") === "true") {
    toggle.classList.remove("is-active");

    menu.style.opacity = "0";
    menu.style.visibility = "hidden";
    menu.style.transform = "translateX(0px) translateY(-100%)";
    menu.style.transition = "all 0.3s ease 0s";

    menu.style.display = "none";
    if (window.scrollY > 0) {
      mainheader.style.backgroundColor = "rgba(0, 0, 0, 1)";
    } else {
      mainheader.style.backgroundColor = "transparent";
    }

    menu.removeAttribute("data-nav-menu-open");
    body.style.overflow = "auto";
    linkWrapper.style.opacity = "1";
    linkWrapper.style.visibility = "visible";
    linkWrapper.style.transform = "translateX(0px) translateY(0px)";
    linkWrapper.style.transition = "all 0.3s ease 0s";
    linkWrapper.style.pointerEvents = "auto";
  } else {
    toggle.classList.add("is-active");

    menu.style.display = "block";

    setTimeout(() => {
      menu.style.opacity = "1";
      menu.style.visibility = "visible";
      menu.style.transform = "translateX(0px) translateY(0px)";
      menu.style.transition = "all 0.3s ease 0s";
      menu.style.pointerEvents = "auto";
      mainheader.style.backgroundColor = "rgba(255, 83, 0, 95%)";
    }, 100);

    menu.setAttribute("data-nav-menu-open", "true");
    body.style.overflow = "hidden";
    linkWrapper.style.opacity = "0";
    linkWrapper.style.visibility = "hidden";
    linkWrapper.style.transform = "translateX(0px) translateY(-100%)";
    linkWrapper.style.transition = "all 0.3s ease 0s";
    linkWrapper.style.pointerEvents = "none";
  }
});

window.addEventListener("scroll", function () {
  if (window.scrollY > 0) {
    mainheader.style.backgroundColor = "rgba(0, 0, 0, 1)";
  } else {
    mainheader.style.backgroundColor = "transparent";
  }
});

document.addEventListener('DOMContentLoaded', function() {
  const menuItems = document.querySelectorAll('.menu-item-has-children');

  menuItems.forEach(item => {
      const submenu = item.querySelector('.lusso_dropdown-menu');

      item.addEventListener('mouseenter', function() {
          if (submenu) {
              submenu.style.opacity = '1';
              submenu.style.top = '32px';
              submenu.style.zIndex = '1000';
              submenu.style.pointerEvents = 'auto';
              submenu.setAttribute('aria-hidden', 'false');
          }
      });

      item.addEventListener('mouseleave', function() {
          if (submenu) {
              submenu.style.opacity = '0';
              submenu.style.top = '64px'; // Slide out of position
              submenu.style.zIndex = '-1';
              submenu.style.pointerEvents = 'none';
              submenu.setAttribute('aria-hidden', 'true');
          }
      });
  });
});